import Menu, { IMenuItem } from "../library/antd/Menu";

const menus: Array<IMenuItem> = [
  {
    label: "지문 빈칸 뚫기",
    key: "blankit",
    path: "/blankit",
  },
  {
    label: "단어 시험지 순서 섞기",
    key: "shuffler",
    path: "/shuffler",
  },
  {
    label: "자료실",
    key: "archieve",
    path: "/archieve",
  },
];

const MainMenuLayout = () => {
  return <Menu items={menus} />;
};

export default MainMenuLayout;
